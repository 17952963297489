import i18next from "@i18next/client";
                    import formatter from "@i18next/formatter";
                    import { useTranslated, getCurrentSite, translation } from "@i18next/utils";
                    formatter(i18next);
                    const currentSite = getCurrentSite(new URL(window.location.href));
                    const translate = useTranslated(currentSite.locale);
                    globalThis.currentSite = currentSite;
                    globalThis.t = translate.t;
                    globalThis.tp = translate.tp;
                    globalThis.ti = translate.ti;
                    globalThis.tl = translate.tl;
                    globalThis.i18next = i18next;
                    globalThis.ts = translation;