import React, { useEffect, useState } from 'react';
import { type ImageMetadata, type ImageInputFormat, type ImageQuality } from 'astro';
import { type ImageProps } from "@nextui-org/image";
import { type ReactRef } from '@nextui-org/react-utils';
import { getURL } from "@module/image-endpoint/client";

interface ImgAttr extends Omit<ImageProps, "ref"> { // ImgHTMLAttributes<HTMLImageElement> {
  __typename?:string|undefined;
  widths?: Array<number>;
  densities?: Array<number | `${number}x`>;
  quality?: ImageQuality;
  format?: ImageInputFormat;
  fit?: "cover" | "contain" | "fill" | "inside" | "outside";
  position?: "top" | "right top" | "right" | "right bottom" | "bottom" | "left bottom" | "left" | "left top";
  ref?: ReactRef<HTMLImageElement>;
}

interface Props extends Omit<ImgAttr, "src"> {
  data?: string | Image | ImageMetadata;
  src?: string | Image | ImageMetadata;
  format?: ImageInputFormat;
  fit?: "cover" | "contain" | "fill" | "inside" | "outside";
  position?: "top" | "right top" | "right" | "right bottom" | "bottom" | "left bottom" | "left" | "left top";
  lazyload?: boolean;
}

const Img: React.FC<Props> = ({ data, src, lazyload = false, ...props }) => {
  src = src ?? data;
  if(!src) return null;
  const img:Image|undefined = (typeof src === "string") ? {src: src}: src;
  const obj:ImgAttr = Object.assign({
    className: img?.class,
    src: img?.src,
    width: img?.width,
    height: img?.height,
    alt: img?.alt,
    title: img?.title
  }, props);
  const {className, src:_src, width: w, height: h, widths, densities, quality, alt, title, format = "webp", fit, position, loading = "lazy", decoding = "async", __typename = undefined, ...attrs} = obj ?? {};
  const width = (typeof w === "string" && /^\d+$/.test(w)) ? parseInt(w) : w;
  const height = (typeof h === "string" && /^\d+$/.test(h)) ? parseInt(h) : h;
  const _url = /(\.svg)(?:\?.*)?/.test(_src ?? "") ? (_src ?? "") : getURL({
    src: _src,
    width,
    widths,
    densities,
    height,
    quality,
    format: format,
    fit,
    position
  });
  const _fallbackSrc = `https://via.placeholder.com/${width}x${height}`;
  const [url, setUrl] = useState(!lazyload ? _url : undefined);
  useEffect(() => {
    setUrl(_url ?? _fallbackSrc);
  }, [src]);
  return <img className={className} src={url} width={width} height={height} alt={ alt ?? title ?? '' } loading={loading} decoding={decoding} {...attrs}/>;
}

export default Img;