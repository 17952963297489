import { AnalyticsBrowser } from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsBrowser;
  }
}

const isProd = import.meta.env.PUBLIC_ENVIRONMENT !== "production" ? false : true;
const segmentKey = !isProd ? "pWnHjGHBp3vk9peBpq6GmG7WllAA9wMe" : "PM2zUvz1t4WPsd1oJkAaSoylG9jPrwL5";

//Lazy / Delayed Loading
var analytics: AnalyticsBrowser;

const getQueryVariable = function (param: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const addSegmentAnonymousID = async function () {
  const _nodeList: NodeListOf<Element> = document.querySelectorAll("a[href*='www.avepointonlineservices.com']");
  if (typeof analytics === 'undefined') return;
  const utmParams = ["utm_source", "utm_medium", "utm_content", "utm_campaign", "gclid"];
  const _user = await analytics.user();
  const ajs_aid = _user?.anonymousId() || null;
  // Generate the UTM query string
  const utmString = utmParams.map(param => {
      const value = getQueryVariable(param);
      return value ? `${param}=${value}` : '';
    })
    .filter(Boolean)
    .join("&");
  _nodeList.forEach(_element => {
    let aHref = _element.getAttribute("href");
    if (aHref) {
      const querySeparator = aHref.includes('?') ? "&" : "?";
      const fullParams = `${ajs_aid ? `ajs_aid=${ajs_aid}` : ''}${ajs_aid && utmString ? '&' : ''}${utmString}`;
      _element.setAttribute("href", `${aHref}${querySeparator}${fullParams}`);
    }
  });
};

function ExecSegmentCode() {
  const params = new URLSearchParams(window.currentSite?.search);
  const url = new URL(decodeURIComponent(getCookie("__cu_gtm_campaign_url") ?? window.currentSite.href));
  const context = {
    campaign: {
      content: url.searchParams.get("utm_content") ?? '', // "search",
      medium: url.searchParams.get("utm_medium") ?? '(none)', // "cpc",
      name: url.searchParams.get("utm_campaign") ?? '(not set)', // "ANZ_NZ_bing_search-engagement_conversions_product-governance_end-user_product_operational-governance-20022024_CMP-11026-C9J1Q",
      source: url.searchParams.get("utm_source") ?? '(direct)', // "bing",
      term: url.searchParams.get("utm_term") ?? '(not provided)' // "Data governance"
    }
  };
  const segmentPage = Object.assign({
    path: window.currentSite?.pathname,
    referrer: decodeURIComponent(referrer),
    search: window.currentSite?.search,
    title: document.title,
    url: window.currentSite?.href
  }, globalThis.segmentTags ?? {});
  if(params.has('utm_content') || params.has('utm_medium') || params.has('utm_campaign') || params.has('utm_source') || params.has('utm_term')){
    analytics.page(segmentPage);
  } else {
    analytics.page(segmentPage, context);
  }

  analytics.ready(async function() {
    var user = await analytics.user();
    let user_id = user.id();
    let traits = user.traits();
    if(!!user_id){
      analytics.identify(user_id, {
        email: traits?.email,
        company: traits?.company,
        first_name: traits?.first_name,
        last_name: traits?.last_name,
        phone: traits?.phone
      });
    } else if(!!traits) {
      if(!!traits.email) {
        const url = isProd ? "https://identify.avepoint.com/api/Segment?code=MEqbXQf8kaRvFAKI5hwfbtLCMSxWC1lgscKU6OMC2PZVQj9xhADZSQ==&source=marketo" : "https://identifydev.sharepointguild.com/api/Segment?code=lTa8YF6SscaGyJmaS5YvnByjz8REDfzTRoZ1RHHuMhw0GIUxHx204A==&source=marketo";
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            "IdentityProfile": {
              "email": traits.email
            }
          })
        };
        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.id) {
              analytics.identify(result.id, {
                email: traits.email
              });
            }
          })
          .catch((error) => console.error(error));
      } else {
        analytics.identify({
          email: traits?.email,
          company: traits?.company,
          first_name: traits?.first_name,
          last_name: traits?.last_name,
          phone: traits?.phone
        });
      }
    }
  });
};

const enabledSegment = () => {
  if(!analytics) {
    const analyticsInstance = new AnalyticsBrowser();
    analytics = window.analytics = analyticsInstance.load({ writeKey: segmentKey });
  }
  ExecSegmentCode();
  if (typeof analytics !== "undefined") {
    addSegmentAnonymousID();
  }
}

const disabledSegment = () => {
  if(!!analytics) {
    analytics.reset();
  }
}

const OsanoChanged = (e: OCMConsent | undefined) => {
  if (e?.ANALYTICS === "ACCEPT") {
    enabledSegment();
  } else {
    disabledSegment();
  }
}

const OsanoReady = () => {
  // window.Osano?.cm?.addEventListener('osano-cm-initialized', () => {
  //   OsanoChanged(window.Osano.cm?.getConsent());
  // });
  window.Osano?.cm?.addEventListener("osano-cm-consent-saved", OsanoChanged);
}

try {
  !window.Osano ? document.querySelector(`script[src^="https://cmp.osano.com/"]`)?.addEventListener("load", OsanoReady) : OsanoReady();
} catch(error) {
  console.error(error);
}